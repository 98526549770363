import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeroDesktop from "../components/HeroDesktop"
import HomeAbout from "../components/HomeAbout"

const IndexPage = () => (
  <Layout home={true}>
    <SEO
      title="Gas fireplace and stoves installers"
      description="Gas and wood burning stove and fireplace installation. "
    />
    <HeroDesktop />
    <HomeAbout />
  </Layout>
)

export default IndexPage
