import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { CgChevronLeft, CgChevronRight } from "react-icons/cg"
import styled from "styled-components"
import Background from "../components/Background"
import { sortQueryData } from "../helpers/query"

const query = graphql`
  {
    images: allFile(
      sort: { fields: name }
      filter: { relativeDirectory: { eq: "hero" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 64, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const HeroDesktop = () => {
  const data = useStaticQuery(query)

  const images = sortQueryData(data).map(item => {
    return item.node.childImageSharp.fluid
  })

  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const lastIndex = images.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  }, [index, images])

  return (
    <Wrapper>
      <Background image={images[index]} key={index}>
        <article>
          <h1>London based gas fireplace installers</h1>
          <h2>Fire skilled Ltd</h2>
          <Link to="/contact" className="btn">
            Get in touch
          </Link>
        </article>
        <button className="prev-btn" onClick={() => setIndex(index - 1)}>
          <CgChevronLeft />
        </button>
        <button className="next-btn" onClick={() => setIndex(index + 1)}>
          <CgChevronRight />
        </button>
        <div className="dots">
          {images.map((_, btnIndex) => {
            return (
              <span
                key={btnIndex}
                onClick={() => setIndex(btnIndex)}
                className={index === btnIndex ? "active" : undefined}
              ></span>
            )
          })}
        </div>
      </Background>
    </Wrapper>
  )
}

export default HeroDesktop

const Wrapper = styled.section`
  min-height: 100vh;

  article {
    width: 85vw;
    max-width: 800px;
    color: var(--color-primary-3);
    text-align: center;
    h1 {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.25;
      margin: 1rem 0 1rem 0;
      letter-spacing: 3px;
    }
    h2 {
      margin: 2rem 0 2rem 0;
      font-size: 2.25rem;
      font-weight: 400;
      color: var(--color-primary-4);
    }
    a {
      background: transparent;
      border: 2px solid var(--bg-4);
      padding: 0.25rem 1rem;
      text-transform: capitalize;
      letter-spacing: 5px;
      color: var(--bg-4);
      font-size: 1rem;
      cursor: pointer;
      transition: var(--transition);
    }
    a:hover {
      border: 2px solid var(--bg-4);
      background: var(--bg-4);
      color: var(--color-primary-1);
    }
    @media (min-width: 768px) {
      a {
        font-size: 1.25rem;
        padding: 0.5rem 1.25rem;
      }
      h1 {
        font-size: 0.8rem;
        letter-spacing: 5px;
      }
      h2 {
        font-size: 4.75rem;
      }
    }
  }
  .next-btn,
  .prev-btn {
    position: absolute;
    top: 95%;
    transform: translateY(-50%);
    font-size: 2.5rem;
    background: transparent;
    color: var(--bg-4);
    border: transparent;
    cursor: pointer;
    transition: var(--transition);
    display: none;
    @media (min-width: 768px) {
      display: block;
      & {
        font-size: 4.5rem;
        top: 50%;
      }
    }
  }
  .next-btn:hover,
  .prev-btn:hover {
    color: var(--color-motif-1);
  }
  .prev-btn {
    left: 0;
  }
  .next-btn {
    right: 0;
  }

  @media (min-width: 1000px) {
    .prev-btn {
      left: 3rem;
    }
    .next-btn {
      right: 3rem;
    }
  }

  .dots {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    justify-content: center;
    @media (min-width: 768px) {
      display: flex;
    }

    span {
      display: block;
      cursor: pointer;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 50%;
      background: transparent;
      margin: 0 1rem;
      border: 1px solid var(--bg-4);
      @media (min-width: 800px) {
        & {
          height: 1rem;
          width: 1rem;
        }
      }
    }
    span.active {
      background-color: var(--bg-4);
    }
  }
`
