import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"

const query = graphql`
  {
    file(relativePath: { eq: "pages/about/about-4.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const HomeAbout = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <h2>
        Welcome to Fire Skilled
        <span>Fireplace and stoves installers</span>
      </h2>
      <article className="about-text">
        <p>
          With over 10 year experience in fireplace industry, we can help you
          find the perfect solution.
        </p>
        <p>
          From chimney survey to full installation of bespoke custom-made
          fireplace with complete flue systems, Fire Skilled Ltd specialize in
          providing wide range of services for different types of fireplaces
          including gas, solid fuel and bio-ethanol Smart Fires.
        </p>
        <p>
          If you are looking for a professional help in fitting, supply,
          installation or gas fire conversion of fireplaces, stoves and burners
          - just let us know and we will make an appointment with you at your
          place. We usually operate in London area but other locations in the UK
          are also possible.
        </p>
        <div className="cta">
          <Link to="/services" className="btn btn--outline">
            Our services
          </Link>
          <Link to="/contact" className="btn btn--outline">
            Contact us
          </Link>
        </div>
      </article>
      <Image fluid={fluid} className="about-img" />
    </Wrapper>
  )
}

export default HomeAbout

const Wrapper = styled.section`
  padding: 3rem 1rem;

  h2 {
    font-size: 1.5rem;
    color: var(--color-primary-1);
    font-weight: 400;
    text-align: center;
    text-transform: none;
    margin-bottom: 2rem;
    font-weight: bold;

    span {
      font-size: 1rem;
      color: var(--color-primary-2);
      font-weight: 400;
      text-align: center;
      text-transform: none;
      display: block;
    }
  }

  .about-img {
    height: 100%;
  }

  .about-text p {
    line-height: 2;
    text-align: justify;
  }

  .cta {
    a:first-of-type {
      margin-right: 1rem;
      margin-bottom: 2rem;
    }
  }

  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 5vh - 9vh);
    padding: 8vh 0 12vh;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    column-gap: 4rem;

    .about-text {
      grid-column: 1 / span 6;

      p {
        text-align: left;
      }
    }

    .about-img {
      grid-column: 7 / -1;
      background: skyblue;
    }

    h2 {
      grid-column: 1 / -1;
      font-size: 2.5rem;
      font-weight: normal;

      span {
        font-size: 1.25rem;
      }
    }

    .cta {
      a:first-of-type {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }
  }
`
